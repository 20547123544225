@use '_modules' as *;

.ecovadis-banner {
  color: white;
  position: relative;
  //   grid-row: calc(var(--row-header, 2) + 1);
  isolation: isolate;
  padding-block: $gutter;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em 2em;
  align-items: center;
  text-align: left;

  &::before {
    content: '';
    background: url('/assets/images/ecovadis-bg.png');
    background-size: cover;
    width: 100vw;
  }

  img {
    aspect-ratio: 1 / 1;
    width: 100%;
    max-width: 200px;
  }

  h2 {
    display: flex;
    color: $white;
    font-weight: 300;
    @include font-montserrat();
    @include heading-h3;
    display: inline;
    line-height: 1;
  }

  p {
    text-wrap: pretty;
    // margin-top: 0.6em;
  }

  .button {
    white-space: nowrap;
    align-self: center;
    --background-colour: #bf8f70;
    --hover-font-colour: #bf8f70;
  }

  p {
    padding: $gap2 0;
    // margin-top: 0;
    display: block;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 1px;
      background-color: white;
      width: 100%;
      left: 0;
      right: 0;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }

  @include break-out(768px) {
    display: grid;
    grid-template-columns: 1.5fr 4fr 3fr 1fr;
    p {
      padding: 0 2em;
      // margin-top: 0;
      display: block;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 1px;
        background-color: white;
        height: 100%;
        top: 0;
        bottom: 0;
        left: unset;
        right: unset;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }

  @include break-in(800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: $gutter2;
    gap: $gap2;
    text-align: center;
    padding-top: $spacer;
    padding-bottom: $spacer;
    text-align: center;
    &::before {
      content: '';
      background: url('/assets/images/ecovadis-bg-mob.png');
      background-size: cover;
      width: 100vw;
      background-color: #1b4220;
    }
    h2 {
      @include heading-h3;
      line-height: 1.2;
    }
    a {
      align-self: auto;
    }
    p {
      margin-top: 0.6em;
    }
  }
}
