.ecovadis-banner {
  color: white;
  position: relative;
  isolation: isolate;
  padding-block: var(--gutter1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em 2em;
  align-items: center;
  text-align: left;
}
.ecovadis-banner::before {
  content: "";
  background: url("/assets/images/ecovadis-bg.png");
  background-size: cover;
  width: 100vw;
}
.ecovadis-banner img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 200px;
}
.ecovadis-banner h2 {
  display: flex;
  color: #FFFFFF;
  font-weight: 300;
  font-family: montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5625em;
  line-height: 1.6;
  display: inline;
  line-height: 1;
}
.ecovadis-banner p {
  text-wrap: pretty;
}
.ecovadis-banner .button {
  white-space: nowrap;
  align-self: center;
  --background-colour: #bf8f70;
  --hover-font-colour: #bf8f70;
}
.ecovadis-banner p {
  padding: calc(var(--gap) * 2) 0;
  display: block;
  position: relative;
}
.ecovadis-banner p::before, .ecovadis-banner p::after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: white;
  width: 100%;
  left: 0;
  right: 0;
}
.ecovadis-banner p::before {
  top: 0;
}
.ecovadis-banner p::after {
  bottom: 0;
}
@media (min-width: 769px) {
  .ecovadis-banner {
    display: grid;
    grid-template-columns: 1.5fr 4fr 3fr 1fr;
  }
  .ecovadis-banner p {
    padding: 0 2em;
    display: block;
    position: relative;
  }
  .ecovadis-banner p::before, .ecovadis-banner p::after {
    position: absolute;
    content: "";
    width: 1px;
    background-color: white;
    height: 100%;
    top: 0;
    bottom: 0;
    left: unset;
    right: unset;
  }
  .ecovadis-banner p::before {
    left: 0;
  }
  .ecovadis-banner p::after {
    right: 0;
  }
}
@media (max-width: 800px) {
  .ecovadis-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: var(--gutter2);
    gap: calc(var(--gap) * 2);
    text-align: center;
    padding-top: var(--spacer1);
    padding-bottom: var(--spacer1);
    text-align: center;
  }
  .ecovadis-banner::before {
    content: "";
    background: url("/assets/images/ecovadis-bg-mob.png");
    background-size: cover;
    width: 100vw;
    background-color: #1b4220;
  }
  .ecovadis-banner h2 {
    font-size: 1.5625em;
    line-height: 1.6;
    line-height: 1.2;
  }
  .ecovadis-banner a {
    align-self: auto;
  }
  .ecovadis-banner p {
    margin-top: 0.6em;
  }
}